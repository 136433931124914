import {
  MEMBER_MANAGEMENT_ASSIGNMENTS_create,
  MEMBER_MANAGEMENT_ASSIGNMENTS_read,
  MEMBER_MANAGEMENT_ASSIGNMENTS_update,
  MEMBER_MANAGEMENT_ASSIGNMENTS_delete,
} from "@/data/permission-types.js";

export const memberManagement = {
  id: "memberManagement",
  name: "Mitgliederverwaltung",
  disabled: false,
  info: "",
  icon: "mdi-account-group",
  children: [
    {
      id: "memberManagement.assignments",
      name: "Aufgabengebiete",
      disabled: false,
      chipsName: "Aufgabengebiete",
      info: "",
      icon: "",
      children: [
        {
          id: `${MEMBER_MANAGEMENT_ASSIGNMENTS_create}`,
          name: "Aufgabengebiete erstellen",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Aufgabengebiete (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${MEMBER_MANAGEMENT_ASSIGNMENTS_read}`,
          name: "Aufgabengebiete lesen",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Aufgabengebiete (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${MEMBER_MANAGEMENT_ASSIGNMENTS_update}`,
          name: "Aufgabengebiete bearbeiten",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Aufgabengebiete (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${MEMBER_MANAGEMENT_ASSIGNMENTS_delete}`,
          name: "Aufgabengebiete löschen",
          disabled: false,
          chipsName: "Mitgliederverwaltung > Aufgabengebiete (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
      ],
    },
  ],
};
