import {
  SCHEDULING_EVENTS_create,
  SCHEDULING_EVENTS_read,
  SCHEDULING_EVENTS_update,
  SCHEDULING_EVENTS_delete,
  SCHEDULING_EVENTS_RESPONSES_OWN_update,
  SCHEDULING_EVENTS_RESPONSES_OTHER_read,
  SCHEDULING_EVENTS_RESPONSES_write,
  SCHEDULING_ARCHIVE_read,
  SCHEDULING_CALENDAR_read,
  SCHEDULING_CALENDAR_FEEDS_read,
  SCHEDULING_TEAMPLANNER_read,
  SCHEDULING_CONFIG_read,
  SCHEDULING_CONFIG_GENERAL_read,
  SCHEDULING_CONFIG_GENERAL_update,
  SCHEDULING_CONFIG_EVENT_TYPES_create,
  SCHEDULING_CONFIG_EVENT_TYPES_read,
  SCHEDULING_CONFIG_EVENT_TYPES_update,
  SCHEDULING_CONFIG_EVENT_TYPES_delete,
  SCHEDULING_CONFIG_EVENT_TEMPLATES_create,
  SCHEDULING_CONFIG_EVENT_TEMPLATES_read,
  SCHEDULING_CONFIG_EVENT_TEMPLATES_update,
  SCHEDULING_CONFIG_EVENT_TEMPLATES_delete,
  MY_ORGANIZATION_DASHBOARD_read,
  MY_ORGANIZATION_NEWS_create,
  MY_ORGANIZATION_NEWS_read,
  MY_ORGANIZATION_NEWS_update,
  MY_ORGANIZATION_NEWS_delete,
  MY_ORGANIZATION_UNITS_read,
  MY_ORGANIZATION_SURVEYS_create,
  MY_ORGANIZATION_SURVEYS_read,
  MY_ORGANIZATION_SURVEYS_update,
  MY_ORGANIZATION_SURVEYS_delete,
  MY_ORGANIZATION_ASSIGNMENTS_read,
  MY_ORGANIZATION_ORGANIZATIONAL_CHART_read,
  // DUTY_MANAGEMENT_REPORTS_create,
  // DUTY_MANAGEMENT_REPORTS_read,
  // DUTY_MANAGEMENT_REPORTS_update,
  // DUTY_MANAGEMENT_REPORTS_delete,
  // DUTY_MANAGEMENT_ARCHIVE_read,
  // DUTY_MANAGEMENT_CONFIG_read,
  // DUTY_MANAGEMENT_CONFIG_update,
  AVAILABILITY_REQUESTS_create,
  AVAILABILITY_REQUESTS_read,
  AVAILABILITY_REQUESTS_update,
  AVAILABILITY_REQUESTS_delete,
  AVAILABILITY_REQUESTS_RESPONSES_OWN_update,
  AVAILABILITY_REQUESTS_RESPONSES_OTHER_read,
  AVAILABILITY_REQUESTS_RESPONSES_write,
  AVAILABILITY_ARCHIVE_read,
  AVAILABILITY_CONFIG_read,
  AVAILABILITY_CONFIG_update,
  AVAILABILITY_CONFIG_REQUEST_TYPES_create,
  AVAILABILITY_CONFIG_REQUEST_TYPES_read,
  AVAILABILITY_CONFIG_REQUEST_TYPES_update,
  AVAILABILITY_CONFIG_REQUEST_TYPES_delete,
  // MEMBER_MANAGEMENT_PERSONNEL_RECORDS_create,
  // MEMBER_MANAGEMENT_PERSONNEL_RECORDS_read,
  // MEMBER_MANAGEMENT_PERSONNEL_RECORDS_update,
  // MEMBER_MANAGEMENT_PERSONNEL_RECORDS_delete,
  // MEMBER_MANAGEMENT_DEADLINES_create,
  // MEMBER_MANAGEMENT_DEADLINES_read,
  // MEMBER_MANAGEMENT_DEADLINES_update,
  // MEMBER_MANAGEMENT_DEADLINES_delete,
  MEMBER_MANAGEMENT_ASSIGNMENTS_create,
  MEMBER_MANAGEMENT_ASSIGNMENTS_read,
  MEMBER_MANAGEMENT_ASSIGNMENTS_update,
  MEMBER_MANAGEMENT_ASSIGNMENTS_delete,
  // MEMBER_MANAGEMENT_SKILLS_create,
  // MEMBER_MANAGEMENT_SKILLS_read,
  // MEMBER_MANAGEMENT_SKILLS_update,
  // MEMBER_MANAGEMENT_SKILLS_delete,
  // MEMBER_MANAGEMENT_RANKS_create,
  // MEMBER_MANAGEMENT_RANKS_read,
  // MEMBER_MANAGEMENT_RANKS_update,
  // MEMBER_MANAGEMENT_RANKS_delete,
  // MEMBER_MANAGEMENT_HONORS_create,
  // MEMBER_MANAGEMENT_HONORS_read,
  // MEMBER_MANAGEMENT_HONORS_update,
  // MEMBER_MANAGEMENT_HONORS_delete,
  // MEMBER_MANAGEMENT_CONFIG_update,
  ADMINISTRATION_PRIVACY_read,
  ADMINISTRATION_PRIVACY_update,
  ADMINISTRATION_AUDIT_read,
  ADMIN_ACCESS,
} from "@/data/permission-types.js";

export const importableRoles = [
  {
    title: "Basis – Gast",
    module: "01–Allgemeine Rollen",
    description:
      "Limitierte Berechtigungen für die Anwendung (notwendig für Dashboard / wenn keine anderen Rollen zugewiesen sind)",
    permissions: [`${MY_ORGANIZATION_DASHBOARD_read}`],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "base.guest",
  },
  // {
  //   title: "Basis – Grundlegende Berechtigungen",
  //   module: "01–Allgemeine Rollen",
  //   description:
  //     "Sammlung an grundlegenden aber limitierten Berechtigungen für die Anwendung",
  //   permissions: [],
  //   isLocked: false,
  //   disabled: false,
  // isImported: true,
  // importVersion: 1,
  // importId: ""
  // },

  {
    title: "Meine Organisation – Benutzer",
    module: "02–Meine Organisation",
    description: "Grundlegende Berechtigungen für das Modul",
    permissions: [
      `${MY_ORGANIZATION_DASHBOARD_read}`,
      `${MY_ORGANIZATION_NEWS_read}`,
      `${MY_ORGANIZATION_UNITS_read}`,
      `${MY_ORGANIZATION_SURVEYS_read}`,
      `${MY_ORGANIZATION_ASSIGNMENTS_read}`,
      `${MY_ORGANIZATION_ORGANIZATIONAL_CHART_read}`,
    ],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "myOrganization.user",
  },
  {
    title: "Meine Organisation – Manager",
    module: "02–Meine Organisation",
    description: "Erweiterte Berechtigungen für das Modul",
    permissions: [
      `${MY_ORGANIZATION_DASHBOARD_read}`,
      `${MY_ORGANIZATION_NEWS_read}`,
      `${MY_ORGANIZATION_NEWS_create}`,
      `${MY_ORGANIZATION_NEWS_update}`,
      `${MY_ORGANIZATION_UNITS_read}`,
      `${MY_ORGANIZATION_SURVEYS_create}`,
      `${MY_ORGANIZATION_SURVEYS_read}`,
      `${MY_ORGANIZATION_SURVEYS_update}`,
      `${MY_ORGANIZATION_ASSIGNMENTS_read}`,
      `${MY_ORGANIZATION_ORGANIZATIONAL_CHART_read}`,
    ],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "myOrganization.manager",
  },
  {
    title: "Meine Organisation – Administrator",
    module: "02–Meine Organisation",
    description: "Alle Berechtigungen für das Modul",
    permissions: [
      `${MY_ORGANIZATION_DASHBOARD_read}`,
      `${MY_ORGANIZATION_NEWS_read}`,
      `${MY_ORGANIZATION_NEWS_create}`,
      `${MY_ORGANIZATION_NEWS_update}`,
      `${MY_ORGANIZATION_NEWS_delete}`,
      `${MY_ORGANIZATION_UNITS_read}`,
      `${MY_ORGANIZATION_SURVEYS_create}`,
      `${MY_ORGANIZATION_SURVEYS_read}`,
      `${MY_ORGANIZATION_SURVEYS_update}`,
      `${MY_ORGANIZATION_SURVEYS_delete}`,
      `${MY_ORGANIZATION_ASSIGNMENTS_read}`,
      `${MY_ORGANIZATION_ORGANIZATIONAL_CHART_read}`,
    ],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "myOrganization.admin",
  },

  {
    title: "Terminplanung – Gast",
    module: "03–Terminplanung",
    description: "Beschränkte Berechtigungen für das Modul",
    permissions: [
      `${SCHEDULING_EVENTS_read}`,
      `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`,
      `${SCHEDULING_CALENDAR_read}`,
    ],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "scheduling.user",
  },
  {
    title: "Terminplanung – Benutzer",
    module: "03–Terminplanung",
    description: "Grundlegende Berechtigungen für das Modul",
    permissions: [
      `${SCHEDULING_EVENTS_read}`,
      `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`,
      `${SCHEDULING_EVENTS_RESPONSES_OTHER_read}`,
      `${SCHEDULING_CALENDAR_read}`,
      `${SCHEDULING_CALENDAR_FEEDS_read}`,
    ],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 2,
    importId: "scheduling.user",
  },
  {
    title: "Terminplanung – Manager",
    module: "03–Terminplanung",
    description: "Erweiterte Berechtigungen für das Modul",
    permissions: [
      `${SCHEDULING_EVENTS_create}`,
      `${SCHEDULING_EVENTS_read}`,
      `${SCHEDULING_EVENTS_update}`,
      `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`,
      `${SCHEDULING_EVENTS_RESPONSES_OTHER_read}`,
      `${SCHEDULING_EVENTS_RESPONSES_write}`,
      `${SCHEDULING_ARCHIVE_read}`,
      `${SCHEDULING_CALENDAR_read}`,
      `${SCHEDULING_CALENDAR_FEEDS_read}`,
      `${SCHEDULING_CONFIG_EVENT_TYPES_read}`,
      `${SCHEDULING_CONFIG_EVENT_TEMPLATES_read}`,
    ],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 2,
    importId: "scheduling.manager",
  },
  {
    title: "Terminplanung – Administrator",
    module: "03–Terminplanung",
    description: "Alle Berechtigungen für das Modul",
    permissions: [
      `${SCHEDULING_EVENTS_create}`,
      `${SCHEDULING_EVENTS_read}`,
      `${SCHEDULING_EVENTS_update}`,
      `${SCHEDULING_EVENTS_delete}`,
      `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`,
      `${SCHEDULING_EVENTS_RESPONSES_OTHER_read}`,
      `${SCHEDULING_EVENTS_RESPONSES_write}`,
      `${SCHEDULING_ARCHIVE_read}`,
      `${SCHEDULING_CALENDAR_read}`,
      `${SCHEDULING_CALENDAR_FEEDS_read}`,
      `${SCHEDULING_TEAMPLANNER_read}`,
      `${SCHEDULING_CONFIG_read}`,
      `${SCHEDULING_CONFIG_GENERAL_read}`,
      `${SCHEDULING_CONFIG_GENERAL_update}`,
      `${SCHEDULING_CONFIG_EVENT_TYPES_create}`,
      `${SCHEDULING_CONFIG_EVENT_TYPES_read}`,
      `${SCHEDULING_CONFIG_EVENT_TYPES_update}`,
      `${SCHEDULING_CONFIG_EVENT_TYPES_delete}`,
      `${SCHEDULING_CONFIG_EVENT_TEMPLATES_create}`,
      `${SCHEDULING_CONFIG_EVENT_TEMPLATES_read}`,
      `${SCHEDULING_CONFIG_EVENT_TEMPLATES_update}`,
      `${SCHEDULING_CONFIG_EVENT_TEMPLATES_delete}`,
    ],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "scheduling.admin",
  },
  // {
  //   title: "Dienstverwaltung – Benutzer",
  //   module: "04–Dienstverwaltung",
  //   description: "Grundlegende Berechtigungen für das Modul",
  //   permissions: [
  //     `${DUTY_MANAGEMENT_REPORTS_create}`,
  //     `${DUTY_MANAGEMENT_REPORTS_read}`,
  //     `${DUTY_MANAGEMENT_REPORTS_update}`,
  //   ],
  //   isLocked: false,
  //   disabled: false,
  //   isImported: true,
  // importVersion: 1,
  //   importId: "dutyManagement.user",
  // },
  // {
  //   title: "Dienstverwaltung – Manager",
  //   module: "04–Dienstverwaltung",
  //   description: "Erweiterte Berechtigungen für das Modul",
  //   permissions: [
  //     `${DUTY_MANAGEMENT_REPORTS_create}`,
  //     `${DUTY_MANAGEMENT_REPORTS_read}`,
  //     `${DUTY_MANAGEMENT_REPORTS_update}`,
  //     `${DUTY_MANAGEMENT_ARCHIVE_read}`,
  //   ],
  //   isLocked: false,
  //   disabled: false,
  //   isImported: true,
  // importVersion: 1,
  //   importId: "dutyManagement.manager",
  // },
  // {
  //   title: "Dienstverwaltung – Administrator",
  //   module: "04–Dienstverwaltung",
  //   description: "Alle Berechtigungen für das Modul",
  //   permissions: [
  //     `${DUTY_MANAGEMENT_REPORTS_create}`,
  //     `${DUTY_MANAGEMENT_REPORTS_read}`,
  //     `${DUTY_MANAGEMENT_REPORTS_update}`,
  //     `${DUTY_MANAGEMENT_REPORTS_delete}`,
  //     `${DUTY_MANAGEMENT_ARCHIVE_read}`,
  //     `${DUTY_MANAGEMENT_CONFIG_read}`,
  //     `${DUTY_MANAGEMENT_CONFIG_update}`,
  //   ],
  //   isLocked: false,
  //   disabled: false,
  //   isImported: true,
  // importVersion: 1,
  //   importId: "dutyManagement.admin",
  // },
  {
    title: "Verfügbarkeit – Benutzer",
    module: "05–Verfügbarkeit",
    description: "Grundlegende Berechtigungen für das Modul",
    permissions: [
      `${AVAILABILITY_REQUESTS_read}`,
      `${AVAILABILITY_REQUESTS_RESPONSES_OWN_update}`,
      `${AVAILABILITY_REQUESTS_RESPONSES_OTHER_read}`,
    ],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "availability.user",
  },
  {
    title: "Verfügbarkeit – Manager",
    module: "05–Verfügbarkeit",
    description: "Erweiterte Berechtigungen für das Modul",
    permissions: [
      `${AVAILABILITY_REQUESTS_create}`,
      `${AVAILABILITY_REQUESTS_read}`,
      `${AVAILABILITY_REQUESTS_update}`,
      `${AVAILABILITY_REQUESTS_delete}`,
      `${AVAILABILITY_REQUESTS_RESPONSES_OWN_update}`,
      `${AVAILABILITY_REQUESTS_RESPONSES_OTHER_read}`,
      `${AVAILABILITY_REQUESTS_RESPONSES_write}`,
      `${AVAILABILITY_ARCHIVE_read}`,
      `${AVAILABILITY_CONFIG_read}`,
      `${AVAILABILITY_CONFIG_update}`,
      `${AVAILABILITY_CONFIG_REQUEST_TYPES_read}`,
    ],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "availability.manager",
  },
  {
    title: "Verfügbarkeit – Administrator",
    module: "05–Verfügbarkeit",
    description: "Alle Berechtigungen für das Modul",
    permissions: [
      `${AVAILABILITY_REQUESTS_create}`,
      `${AVAILABILITY_REQUESTS_read}`,
      `${AVAILABILITY_REQUESTS_update}`,
      `${AVAILABILITY_REQUESTS_delete}`,
      `${AVAILABILITY_REQUESTS_RESPONSES_OWN_update}`,
      `${AVAILABILITY_REQUESTS_RESPONSES_OTHER_read}`,
      `${AVAILABILITY_REQUESTS_RESPONSES_write}`,
      `${AVAILABILITY_ARCHIVE_read}`,
      `${AVAILABILITY_CONFIG_read}`,
      `${AVAILABILITY_CONFIG_update}`,
      `${AVAILABILITY_CONFIG_REQUEST_TYPES_create}`,
      `${AVAILABILITY_CONFIG_REQUEST_TYPES_read}`,
      `${AVAILABILITY_CONFIG_REQUEST_TYPES_update}`,
      `${AVAILABILITY_CONFIG_REQUEST_TYPES_delete}`,
    ],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "availability.admin",
  },

  {
    title: "Mitgliederverwaltung – Benutzer",
    module: "06–Mitgliederverwaltung",
    description: "Grundlegende Berechtigungen für das Modul",
    permissions: [],
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "memberManagement.user",
    isLocked: false,
  },
  {
    title: "Mitgliederverwaltung – Manager",
    module: "06–Mitgliederverwaltung",
    description: "Erweiterte Berechtigungen für das Modul",
    permissions: [
      `${MEMBER_MANAGEMENT_ASSIGNMENTS_create}`,
      `${MEMBER_MANAGEMENT_ASSIGNMENTS_read}`,
      `${MEMBER_MANAGEMENT_ASSIGNMENTS_update}`,
    ],
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "memberManagement.manager",
    isLocked: false,
  },
  {
    title: "Mitgliederverwaltung – Administrator",
    module: "06–Mitgliederverwaltung",
    description: "Alle Berechtigungen für das Modul",
    permissions: [
      // `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_create}`,
      // `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_read}`,
      // `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_update}`,
      // `${MEMBER_MANAGEMENT_PERSONNEL_RECORDS_delete}`,
      // `${MEMBER_MANAGEMENT_DEADLINES_create}`,
      // `${MEMBER_MANAGEMENT_DEADLINES_read}`,
      // `${MEMBER_MANAGEMENT_DEADLINES_update}`,
      // `${MEMBER_MANAGEMENT_DEADLINES_delete}`,
      `${MEMBER_MANAGEMENT_ASSIGNMENTS_create}`,
      `${MEMBER_MANAGEMENT_ASSIGNMENTS_read}`,
      `${MEMBER_MANAGEMENT_ASSIGNMENTS_update}`,
      `${MEMBER_MANAGEMENT_ASSIGNMENTS_delete}`,
      // `${MEMBER_MANAGEMENT_SKILLS_create}`,
      // `${MEMBER_MANAGEMENT_SKILLS_read}`,
      // `${MEMBER_MANAGEMENT_SKILLS_update}`,
      // `${MEMBER_MANAGEMENT_SKILLS_delete}`,
      // `${MEMBER_MANAGEMENT_RANKS_create}`,
      // `${MEMBER_MANAGEMENT_RANKS_read}`,
      // `${MEMBER_MANAGEMENT_RANKS_update}`,
      // `${MEMBER_MANAGEMENT_RANKS_delete}`,
      // `${MEMBER_MANAGEMENT_HONORS_create}`,
      // `${MEMBER_MANAGEMENT_HONORS_read}`,
      // `${MEMBER_MANAGEMENT_HONORS_update}`,
      // `${MEMBER_MANAGEMENT_HONORS_delete}`,
      // `${MEMBER_MANAGEMENT_CONFIG_update}`,
    ],
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "memberManagement.admin",
    isLocked: false,
  },

  //   {
  //     title: "Prozessmanagement – Administrator",
  //     module: "07–Prozessmanagement",
  //     description: "",
  //     permissions: [],
  //     isLocked: false,
  //     disabled: false,
  // isImported: true,
  // importVersion: 1,
  // importId: "processManagement.admin"
  //   },
  //   {
  //     title: "Inventarverwaltung – Administrator",
  //     module: "08–Inventarverwaltung",
  //     description: "",
  //     permissions: [],
  //     isLocked: false,
  //     disabled: false,
  // isImported: true,
  // importVersion: 1,
  // importId: "inventory.admin"
  //   },
  //   {
  //     title: "Finanzen – Administrator",
  //     module: "9–Finanzen",
  //     description: "",
  //     permissions: [],
  //     isLocked: false,
  //     disabled: false,
  // isImported: true,
  // importVersion: 1,
  // importId: "finances.admin"
  //   },

  // {
  //   title: "Statistik – Benutzer",
  //   module: "10–Statistik",
  //   description: "Grundlegende Berechtigungen für das Modul",
  //   permissions: [],
  //   isLocked: false,
  //   disabled: false,
  //   isImported: true,
  // importVersion: 1,
  //   importId: "statistics.user"
  // },
  // {
  //   title: "Statistik – Manager",
  //   module: "10–Statistik",
  //   description: "Erweiterte Berechtigungen für das Modul",
  //   permissions: [],
  //   isLocked: false,
  //   disabled: false,
  //   isImported: true,
  // importVersion: 1,
  //   importId: ""
  // },
  // {
  //   title: "Statistik – Administrator",
  //   module: "10–Statistik",
  //   description: "Alle Berechtigungen für das Modul",
  //   permissions: [],
  //   isLocked: false,
  //   disabled: false,
  //   isImported: true,
  // importVersion: 1,
  //   importId: ""
  // },

  {
    title: "Administration – Datenschutzbeauftragter",
    module: "11–Administration",
    description:
      "Berechtigungen zur Ausübung der Aufgaben des Datenschutzbeauftragten",
    permissions: [
      `${ADMINISTRATION_PRIVACY_read}`,
      `${ADMINISTRATION_PRIVACY_update}`,
      `${ADMINISTRATION_AUDIT_read}`,
    ],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "admin.dpo",
  },
  // {
  //   title: "Administration – Manager",
  //   module: "11–Administration",
  //   description: "Erweiterte Berechtigungen für das Modul",
  //   permissions: [`${}`],
  //   isLocked: false,
  //   disabled: false,
  //   isImported: true,
  //   importVersion: 1,
  //   importId: "admin.manager",
  // },
  {
    title: "Administration – Administrator (Anwendungsweit)",
    module: "11–Administration",
    description: "Alle Berechtigungen innerhalb der Anwendung",
    permissions: [`${ADMIN_ACCESS}`],
    isLocked: false,
    disabled: false,
    isImported: true,
    importVersion: 1,
    importId: "admin.admin",
  },
];
