import {
  MY_ORGANIZATION_DASHBOARD_read,
  SELF_SERVICE_read,
  SUPPORT_read,
} from "@/data/permission-types.js";
import { myOrganization } from "@/data/permissions/permissions-my-organization.js";
import { scheduling } from "@/data/permissions/permissions-scheduling.js";
import { dutyManagement } from "@/data/permissions/permissions-duty-management.js";
import { availability } from "@/data/permissions/permissions-availability.js";
import { memberManagement } from "@/data/permissions/permissions-member-management.js";
// import { stats } from "@/data/permissions/permissions-stats.js";
import { admin } from "@/data/permissions/permissions-admin.js";

export const permissionsTree = [
  {
    id: "all_access",
    name: "Alle Berechtigungen",
    icon: "",
    disabled: false,
    info: "",
    children: [
      {
        id: `${MY_ORGANIZATION_DASHBOARD_read}`,
        name: "Dashboard",
        disabled: false,
        chipsName: "Dashboard",
        info: "",
        icon: "mdi-monitor-dashboard",
      },
      {
        id: `${SELF_SERVICE_read}`,
        name: "Self-Service",
        disabled: false,
        chipsName: "Self-Service",
        info: "",
        icon: "mdi-touch-text-outline‚",
      },
      myOrganization,
      scheduling,
      dutyManagement,
      availability,
      memberManagement,
      admin,
      {
        id: `${SUPPORT_read}`,
        name: "Support",
        disabled: false,
        info: "",
        icon: "mdi-lifebuoy",
        // children: [
        //   {
        //     id: `${SUPPORT_read}`,
        //     name: "Ansicht",
        //     disabled: false,
        //     chipsName: "Support > Support (Ansicht)",
        //     info: "",
        //     icon: "",
        //   },
        // {
        //   id: `${SUPPORT_read}`,
        //   name: "Support-Session starten und Zugriff freigeben",
        //   disabled: false,
        //   chipsName: "Support > Support (Zugriff freigeben)",
        //   info: "",
        //   icon: "mdi-face-agent",
        // },
        // ],
      },
    ],
  },
];
