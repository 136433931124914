<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader
              icon="mdi-account-supervisor-circle"
              title="Allgemein"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-col md="12" cols="12">
                      <v-text-field
                        v-model.trim="role.title"
                        :counter="35"
                        label="Bezeichnung"
                        :rules="[rules.required]"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model.trim="role.description"
                        counter
                        clearable
                        no-resize
                        label="Beschreibung"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <v-alert
              type="warning"
              border="left"
              colored-border
              icon="mdi-alert"
              elevation="2"
              class="mt-6"
            >
              <div class="title">Hinweis</div>
              <div class="mt-2">
                Die Steuerung von Berechtigungen für deaktivierte Funktionen ist
                nicht möglich. Sobald die Funktionen einzeln freigeschaltet
                werden, können die Berechtigungen verwaltet werden. Du wirst
                darüber gesondert informiert, um die Berechtigungen deiner
                Rollen entsprechend anzupassen.
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-account-supervisor-circle"
              title="Berechtigungen"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-col cols="12" sm="12" md="12">
                      <v-card-actions class="mb-1">
                        <v-text-field
                          v-model="search"
                          label="Suchen"
                          outlined
                          hide-details
                          clearable
                          class="mr-2"
                        ></v-text-field>
                        <v-btn
                          text
                          @click="role.permissions = []"
                          :disabled="role.permissions.length === 0"
                        >
                          Zurücksetzen
                        </v-btn>
                      </v-card-actions>
                      <v-row>
                        <v-col>
                          <v-card-text>
                            <v-treeview
                              v-model="role.permissions"
                              dense
                              :items="permissions"
                              :search="search"
                              :filter="filter"
                              selected-color="primary"
                              selection-type="leaf"
                              item-disabled="disabled"
                              open-on-click
                              selectable
                              :open.sync="openNodes"
                              transition
                              expand-icon="mdi-chevron-down"
                            >
                              <template v-slot:label="{ item }">
                                <v-icon
                                  v-if="item.icon"
                                  dense
                                  :disabled="item.disabled"
                                  class="mr-1"
                                >
                                  {{ item.icon }}
                                </v-icon>
                                {{ item.name }}
                              </template>
                            </v-treeview>
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import { permissionsTree } from "@/data/permission-tree.js";
import Subheader from "@/components/_systemwide/Subheader.vue";
export default {
  name: "role-editor",
  props: {
    role: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
  },
  data() {
    return {
      openNodes: ["all_access"],
      search: null,
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  model: {
    prop: "role",
    event: "roleChange",
  },
  computed: {
    roleLocal: {
      get: function () {
        return this.role;
      },
      set: function (value) {
        this.$emit("roleChange", value);
      },
    },
    permissions() {
      return permissionsTree;
    },
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
};
</script>
