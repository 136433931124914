import {
  AVAILABILTY,
  AVAILABILITY_LEAVES_create_own,
  AVAILABILITY_LEAVES_read_own,
  AVAILABILITY_LEAVES_update_own,
  AVAILABILITY_LEAVES_delete_own,
  AVAILABILITY_LEAVES_read_all,
  AVAILABILITY_LEAVES_manage_all,
  AVAILABILITY_UNITS_create,
  AVAILABILITY_UNITS_read,
  AVAILABILITY_UNITS_update,
  AVAILABILITY_UNITS_delete,
  //   AVAILABILITY_FORECASTS_read,
  AVAILABILITY_REQUESTS_create,
  AVAILABILITY_REQUESTS_read,
  AVAILABILITY_REQUESTS_update,
  AVAILABILITY_REQUESTS_delete,
  AVAILABILITY_REQUESTS_RESPONSES_OWN_update,
  AVAILABILITY_REQUESTS_RESPONSES_OTHER_read,
  AVAILABILITY_REQUESTS_RESPONSES_write,
  AVAILABILITY_ARCHIVE_read,
  AVAILABILITY_CONFIG_read,
  AVAILABILITY_CONFIG_GENERAL_read,
  AVAILABILITY_CONFIG_GENERAL_update,
  AVAILABILITY_CONFIG_REQUEST_TYPES_create,
  AVAILABILITY_CONFIG_REQUEST_TYPES_read,
  AVAILABILITY_CONFIG_REQUEST_TYPES_update,
  AVAILABILITY_CONFIG_REQUEST_TYPES_delete,
} from "@/data/permission-types.js";

export const availability = {
  id: `${AVAILABILTY}`,
  name: "Verfügbarkeit",
  disabled: false,
  info: "",
  icon: "mdi-checkbox-multiple-marked-circle",
  children: [
    {
      id: "verfuegbarkeit.Abwesenheiten",
      name: "Abwesenheiten",
      disabled: true,
      chipsName: "Abwesenheiten",
      info: "",
      icon: "",
      children: [
        {
          id: `${AVAILABILITY_LEAVES_create_own}`,
          name: "Abmeldung erstellen",
          disabled: false,
          chipsName: "Verfügbarkeit > Abwesenheiten (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${AVAILABILITY_LEAVES_read_own}`,
          name: "Abmeldung lesen",
          disabled: false,
          chipsName: "Verfügbarkeit > Abwesenheiten (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${AVAILABILITY_LEAVES_update_own}`,
          name: "Abmeldung bearbeiten",
          disabled: false,
          chipsName: "Verfügbarkeit > Abwesenheiten (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${AVAILABILITY_LEAVES_delete_own}`,
          name: "Abmeldung löschen",
          disabled: false,
          chipsName: "Verfügbarkeit > Abwesenheiten (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
        {
          id: `${AVAILABILITY_LEAVES_read_all}`,
          name: "Alle Abwesenheiten lesen",
          disabled: false,
          chipsName: "Verfügbarkeit > Abwesenheiten (Alle lesen)",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
        {
          id: `${AVAILABILITY_LEAVES_manage_all}`,
          name: "Alle Abwesenheiten verwalten",
          disabled: false,
          chipsName: "Verfügbarkeit > Abwesenheiten (Alle verwalten)",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
      ],
    },
    {
      id: "verfuegbarkeit.abfragen",
      name: "Abfragen",
      disabled: false,
      chipsName: "Abfragen",
      info: "",
      icon: "",
      children: [
        {
          id: `${AVAILABILITY_REQUESTS_create}`,
          name: "Abfragen erstellen",
          disabled: false,
          chipsName: "Verfügbarkeit > Abfragen (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${AVAILABILITY_REQUESTS_read}`,
          name: "Abfragen lesen",
          disabled: false,
          chipsName: "Verfügbarkeit > Abfragen (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${AVAILABILITY_REQUESTS_update}`,
          name: "Abfragen bearbeiten",
          disabled: false,
          chipsName: "Verfügbarkeit > Abfragen (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${AVAILABILITY_REQUESTS_delete}`,
          name: "Abfragen löschen",
          disabled: false,
          chipsName: "Verfügbarkeit > Abfragen (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
        // Advanced permissions
        {
          id: `${AVAILABILITY_REQUESTS_RESPONSES_OWN_update}`,
          name: "Eigene Rückmeldung abgeben",
          disabled: false,
          chipsName: "Verfügbarkeit > Abfragen (Eigene Rückmeldung)",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
        {
          id: `${AVAILABILITY_REQUESTS_RESPONSES_OTHER_read}`,
          name: "Alle Rückmeldungen lesen",
          disabled: false,
          chipsName: "Verfügbarkeit > Abfragen (Rückmeldungen lesen)",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
        {
          id: `${AVAILABILITY_REQUESTS_RESPONSES_write}`,
          name: "Alle Rückmeldungen verwalten",
          disabled: false,
          chipsName: "Verfügbarkeit > Abfragen (Rückmeldungen verwalten)",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
      ],
    },
    {
      id: "verfuegbarkeit.einsatzmittel",
      name: "Einsatzmittel",
      disabled: true,
      chipsName: "Einsatzmittel",
      info: "",
      icon: "",
      children: [
        {
          id: `${AVAILABILITY_UNITS_create}`,
          name: "Einsatzmittel erstellen",
          disabled: false,
          chipsName: "Verfügbarkeit > Einsatzmittel (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${AVAILABILITY_UNITS_read}`,
          name: "Einsatzmittel lesen",
          disabled: false,
          chipsName: "Verfügbarkeit > Einsatzmittel (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${AVAILABILITY_UNITS_update}`,
          name: "Einsatzmittel bearbeiten",
          disabled: false,
          chipsName: "Verfügbarkeit > Einsatzmittel (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${AVAILABILITY_UNITS_delete}`,
          name: "Einsatzmittel löschen",
          disabled: false,
          chipsName: "Verfügbarkeit > Einsatzmittel (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
      ],
    },
    {
      id: "verfuegbarkeit.vorausplanung",
      name: "Vorausplanung",
      disabled: true,
      chipsName: "Vorausplanung",
      info: "",
      icon: "",
      children: [],
    },
    {
      id: `${AVAILABILITY_ARCHIVE_read}`,
      name: "Archiv",
      disabled: false,
      chipsName: "Verfügbarkeit > Archiv",
      info: "",
      icon: "",
    },
    {
      id: "verfuegbarkeit.konfiguration",
      name: "Konfiguration",
      disabled: false,
      chipsName: "Konfiguration",
      info: "",
      icon: "",
      children: [
        {
          id: `${AVAILABILITY_CONFIG_read}`, //TODO:
          name: "Ansicht (notwendig für Berechtigungen)",
          disabled: false,
          chipsName: "Verfügbarkeit > Konfiguration (Ansicht)",
          info: "",
          icon: "",
        },
        {
          id: `${AVAILABILITY_CONFIG_GENERAL_read}`,
          name: "Konfiguration lesen",
          disabled: false,
          chipsName: "Verfügbarkeit > Konfiguration > Allgemein (Lesen)",
          info: "",
          icon: "mdi-eye",
        },
        {
          id: `${AVAILABILITY_CONFIG_GENERAL_update}`,
          name: "Konfiguration bearbeiten",
          disabled: false,
          chipsName: "Verfügbarkeit > Konfiguration > Allgemein",
          info: "",
          icon: "mdi-pencil",
        },
        {
          id: "verfuegbarkeit.konfiguration.veranstaltungsarten",
          name: "Abfragearten",
          disabled: false,
          info: "",
          children: [
            {
              id: `${AVAILABILITY_CONFIG_REQUEST_TYPES_create}`,
              name: "Abfragearten erstellen",
              disabled: false,
              chipsName:
                "Verfügbarkeit > Konfiguration > Abfragearten (Erstellen)",
              info: "",
              icon: "mdi-plus-circle",
            },
            {
              id: `${AVAILABILITY_CONFIG_REQUEST_TYPES_read}`,
              name: "Abfragearten lesen",
              disabled: false,
              chipsName: "Verfügbarkeit > Konfiguration > Abfragearten (Lesen)",
              info: "",
              icon: "mdi-eye-circle",
            },
            {
              id: `${AVAILABILITY_CONFIG_REQUEST_TYPES_update}`,
              name: "Abfragearten bearbeiten",
              disabled: false,
              chipsName:
                "Verfügbarkeit > Konfiguration > Abfragearten (Bearbeiten)",
              info: "",
              icon: "mdi-pencil-circle",
            },
            {
              id: `${AVAILABILITY_CONFIG_REQUEST_TYPES_delete}`,
              name: "Abfragearten löschen",
              disabled: false,
              chipsName:
                "Verfügbarkeit > Konfiguration > Abfragearten (Löschen)",
              info: "",
              icon: "mdi-delete-circle",
            },
          ],
        },
      ],
    },
  ],
};
