import {
  SCHEDULING,
  SCHEDULING_EVENTS_create,
  SCHEDULING_EVENTS_read,
  SCHEDULING_EVENTS_update,
  SCHEDULING_EVENTS_delete,
  SCHEDULING_EVENTS_RESPONSES_OWN_update,
  SCHEDULING_EVENTS_RESPONSES_OTHER_read,
  SCHEDULING_EVENTS_RESPONSES_write,
  // SCHEDULING_EVENTS_rueckmeldungNachFrist,
  SCHEDULING_ARCHIVE_read,
  SCHEDULING_CONFIG_read,
  SCHEDULING_CONFIG_GENERAL_read,
  SCHEDULING_CONFIG_EVENT_TYPES_create,
  SCHEDULING_CONFIG_EVENT_TYPES_read,
  SCHEDULING_CONFIG_EVENT_TYPES_update,
  SCHEDULING_CONFIG_EVENT_TYPES_delete,
  SCHEDULING_CONFIG_EVENT_TEMPLATES_create,
  SCHEDULING_CONFIG_EVENT_TEMPLATES_read,
  SCHEDULING_CONFIG_EVENT_TEMPLATES_update,
  SCHEDULING_CONFIG_EVENT_TEMPLATES_delete,
  SCHEDULING_CALENDAR_read,
  SCHEDULING_CALENDAR_FEEDS_read,
  SCHEDULING_TEAMPLANNER_read,
} from "@/data/permission-types.js";

export const scheduling = {
  id: `${SCHEDULING}`,
  name: "Terminplanung",
  disabled: false,
  info: "",
  icon: "mdi-calendar",
  children: [
    {
      id: "terminplanung.veranstaltungen",
      name: "Veranstaltungen",
      disabled: false,
      chipsName: "Veranstaltungen",
      info: "",
      icon: "",
      children: [
        {
          id: `${SCHEDULING_EVENTS_create}`,
          name: "Veranstaltungen erstellen",
          disabled: false,
          chipsName: "Terminplanung > Veranstaltungen (Erstellen)",
          info: "",
          icon: "mdi-plus-circle",
        },
        {
          id: `${SCHEDULING_EVENTS_read}`,
          name: "Veranstaltungen lesen",
          disabled: false,
          chipsName: "Terminplanung > Veranstaltungen (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${SCHEDULING_EVENTS_update}`,
          name: "Veranstaltungen bearbeiten",
          disabled: false,
          chipsName: "Terminplanung > Veranstaltungen (Bearbeiten)",
          info: "",
          icon: "mdi-pencil-circle",
        },
        {
          id: `${SCHEDULING_EVENTS_delete}`,
          name: "Veranstaltungen löschen",
          disabled: false,
          chipsName: "Terminplanung > Veranstaltungen (Löschen)",
          info: "",
          icon: "mdi-delete-circle",
        },
        // Advanced permissions
        {
          id: `${SCHEDULING_EVENTS_RESPONSES_OWN_update}`,
          name: "Eigene Rückmeldung abgeben",
          disabled: false,
          chipsName: "Terminplanung > Veranstaltungen (Eigene Rückmeldung)",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
        {
          id: `${SCHEDULING_EVENTS_RESPONSES_OTHER_read}`,
          name: "Alle Rückmeldungen lesen",
          disabled: false,
          chipsName: "Terminplanung > Veranstaltungen (Rückmeldungen lesen)",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
        {
          id: `${SCHEDULING_EVENTS_RESPONSES_write}`,
          name: "Alle Rückmeldungen verwalten",
          disabled: false,
          chipsName:
            "Terminplanung > Veranstaltungen (Rückmeldungen verwalten)",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
      ],
    },
    {
      id: "terminplanung.kalender",
      name: "Kalender",
      disabled: false,
      chipsName: "Terminplanung > Kalender",
      info: "",
      icon: "",
      children: [
        {
          id: `${SCHEDULING_CALENDAR_read}`,
          name: "Kalender lesen",
          disabled: false,
          chipsName: "Terminplanung > Kalender (Lesen)",
          info: "",
          icon: "mdi-eye-circle",
        },
        {
          id: `${SCHEDULING_CALENDAR_FEEDS_read}`,
          name: "Kalenderfeeds",
          disabled: false,
          chipsName: "Terminplanung > Kalender (Feeds))",
          info: "",
          icon: "mdi-dots-horizontal-circle",
        },
      ],
    },
    {
      id: `${SCHEDULING_TEAMPLANNER_read}`,
      name: "Teamplaner",
      disabled: false,
      chipsName: "Terminplanung > Teamplaner",
      info: "",
      icon: "",
    },
    {
      id: `${SCHEDULING_ARCHIVE_read}`,
      name: "Archiv",
      disabled: false,
      chipsName: "Terminplanung > Archiv",
      info: "",
      icon: "",
    },
    {
      id: "terminplanung.konfiguration",
      name: "Konfiguration",
      disabled: false,
      chipsName: "Terminplanung > Konfiguration",
      info: "",
      icon: "",
      children: [
        {
          id: `${SCHEDULING_CONFIG_read}`, //TODO:
          name: "Ansicht (notwendig für Berechtigungen)",
          disabled: false,
          chipsName: "Terminplanung > Konfiguration (Ansicht)",
          info: "",
          icon: "",
        },
        {
          id: `${SCHEDULING_CONFIG_GENERAL_read}`,
          name: "Konfiguration lesen",
          disabled: false,
          chipsName: "Terminplanung > Konfiguration > Allgemein (Lesen)",
          info: "",
          icon: "mdi-eye",
        },
        {
          id: `${SCHEDULING_CONFIG_GENERAL_read}`,
          name: "Konfiguration bearbeiten",
          disabled: false,
          chipsName: "Terminplanung > Konfiguration > Allgemein",
          info: "",
          icon: "mdi-pencil",
        },
        {
          id: "terminplanung.konfiguration.veranstaltungsarten",
          name: "Veranstaltungsarten",
          disabled: false,
          info: "",
          children: [
            {
              id: `${SCHEDULING_CONFIG_EVENT_TYPES_create}`,
              name: "Veranstaltungsarten erstellen",
              disabled: false,
              chipsName:
                "Terminplanung > Konfiguration > Veranstaltungsarten (Erstellen)",
              info: "",
              icon: "mdi-plus-circle",
            },
            {
              id: `${SCHEDULING_CONFIG_EVENT_TYPES_read}`,
              name: "Veranstaltungsarten lesen",
              disabled: false,
              chipsName:
                "Terminplanung > Konfiguration > Veranstaltungsarten (Lesen)",
              info: "",
              icon: "mdi-eye-circle",
            },
            {
              id: `${SCHEDULING_CONFIG_EVENT_TYPES_update}`,
              name: "Veranstaltungsarten bearbeiten",
              disabled: false,
              chipsName:
                "Terminplanung > Konfiguration > Veranstaltungsarten (Bearbeiten)",
              info: "",
              icon: "mdi-pencil-circle",
            },
            {
              id: `${SCHEDULING_CONFIG_EVENT_TYPES_delete}`,
              name: "Veranstaltungsarten löschen",
              disabled: false,
              chipsName:
                "Terminplanung > Konfiguration > Veranstaltungsarten (Löschen)",
              info: "",
              icon: "mdi-delete-circle",
            },
          ],
        },
        {
          id: "terminplanung.konfiguration.vorlagen",
          name: "Vorlagen",
          disabled: false,
          info: "",
          children: [
            {
              id: `${SCHEDULING_CONFIG_EVENT_TEMPLATES_create}`,
              name: "Vorlagen erstellen",
              disabled: false,
              chipsName: "Terminplanung > Konfiguration > Vorlagen (Erstellen)",
              info: "",
              icon: "mdi-plus-circle",
            },
            {
              id: `${SCHEDULING_CONFIG_EVENT_TEMPLATES_read}`,
              name: "Vorlagen lesen",
              disabled: false,
              chipsName: "Terminplanung > Konfiguration > Vorlagen (Lesen)",
              info: "",
              icon: "mdi-eye-circle",
            },
            {
              id: `${SCHEDULING_CONFIG_EVENT_TEMPLATES_update}`,
              name: "Vorlagen bearbeiten",
              disabled: false,
              chipsName:
                "Terminplanung > Konfiguration > Vorlagen (Bearbeiten)",
              info: "",
              icon: "mdi-pencil-circle",
            },
            {
              id: `${SCHEDULING_CONFIG_EVENT_TEMPLATES_delete}`,
              name: "Vorlagen löschen",
              disabled: false,
              chipsName: "Terminplanung > Konfiguration > Vorlagen (Löschen)",
              info: "",
              icon: "mdi-delete-circle",
            },
          ],
        },
      ],
    },
  ],
};
